import Link from 'next/link'
import TagManager from 'react-gtm-module'

const ViewDetailsButton = ({
  title,
  link,
  event,
}: {
  title: string
  link: string
  event: string
}) => (
  <Link
    href={link}
    onClick={() => {
      TagManager.dataLayer({
        dataLayer: {
          event: event,
          ...(sessionStorage.getItem('utmAndGclid') &&
            JSON.parse(sessionStorage.getItem('utmAndGclid') as string)),
        },
      })
    }}
    className="inline-block cursor-pointer rounded-full bg-yellow-450 px-6 py-3 lg:px-8 lg:py-[18px]"
  >
    <span className="text-base font-semibold text-white lg:text-xl">{title}</span>
  </Link>
)

export default ViewDetailsButton
