'use client'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { useState } from 'react'
import Slider, { CustomArrowProps, Settings } from 'react-slick'

import styles from '../Home.module.scss'

interface ICarouselRenderProps {
  contents: {
    imgUrl: string
    title: string
    desc: string
  }[]
}

interface IIntroReviewCarouselProps {
  contents: {
    boldPc: string
    boldM: string
    textPc: string
    textM: string
    author: string
  }[]
}

export const IntroReviewSlider = ({ contents }: IIntroReviewCarouselProps) => {
  const settings: Settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    vertical: true,
    cssEase: 'linear',
    pauseOnHover: false,
    pauseOnFocus: false,
  }

  return (
    <div className="mx-auto flex h-full w-full max-w-[735px] items-center justify-center rounded-[20px] bg-white shadow-[5.458px_16.374px_60.646px_-7.884px_rgba(240,210,206,0.30)] md:max-w-[983px] lg:max-w-[1100px] lg:rounded-[32px]">
      <Slider {...settings}>
        {contents.map(el => (
          <div
            key={`${el.boldPc}-${el.author}`}
            className="flex h-full w-full items-center justify-center px-6 py-5 lg:p-11"
          >
            <div className="flex h-full w-full flex-col items-start justify-center gap-3 lg:flex-row lg:gap-5">
              <img
                className="h-6 w-6 lg:h-10 lg:w-10"
                src={
                  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/intro_slider_deco_yellow450.png'
                }
                alt="-"
              />
              <div className="flex-1">
                <p className="mb-2 whitespace-pre-wrap text-lg leading-[1.44] text-trueGray-800 lg:mb-3 lg:text-[28px] lg:leading-[1.35]">
                  <b className="text-yellow-450">
                    <span className="hidden font-semibold lg:inline">{el.boldPc}</span>
                    <span className="inline font-semibold lg:hidden">{el.boldM}</span>
                  </b>
                  <span className="hidden font-semibold lg:inline">{el.textPc}</span>
                  <span className="inline font-semibold lg:hidden">{el.textM}</span>
                </p>
                <p className="text-[15px] text-gray-500 lg:text-xl">{el.author}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export const PortfolioSliderItem = ({ contents }: ICarouselRenderProps) => {
  const [showPrevArrow, setShowPrevArrow] = useState(false)

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    centerMode: true,
    centerPadding: '34%',
    touchMove: true,
    className: 'carousel',
    nextArrow: <CustomNextArrow />,
    prevArrow: showPrevArrow ? <CustomPrevArrow /> : undefined,
    afterChange: () => setShowPrevArrow(true),
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          centerPadding: '25%',
        },
      },
      {
        breakpoint: 1023,
        settings: {
          centerPadding: '20%',
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '8px',
        },
      },
    ],
  }

  return (
    <Slider {...settings}>
      {contents.map(({ imgUrl, title, desc }) => (
        <div key={title}>
          <div className="mx-auto">
            <div className="overflow-hidden">
              <div className="relative w-full overflow-hidden rounded-2xl pb-[55.5%] md:rounded-2xl lg:rounded-[20px]">
                <img
                  src={imgUrl}
                  className="absolute left-0 top-0 mx-auto block h-full w-full object-cover"
                  loading="lazy"
                />
              </div>
              <div className="mt-3 flex w-full flex-row items-center justify-center gap-1.5 md:mt-7 md:gap-2">
                <p className="whitespace-nowrap text-base font-semibold text-white lg:text-lg">
                  {title}
                </p>
                <p className="lg::text-lg truncate text-base text-[#d4d4d4]">{desc}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  )
}

interface IVideoCardProps {
  imgUrl: string
  hashtag: string
  title: string
  videoUrl: string
  onClick: () => void
}

interface VideoSliderItemProps {
  contents: Omit<IVideoCardProps, 'onClick'>[]
  onClickVideoCard: (videoUrl: string) => void
}

export const VideoSliderItem = ({ contents, onClickVideoCard }: VideoSliderItemProps) => {
  const [showPrevArrow, setShowPrevArrow] = useState(false)

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    centerMode: true,
    centerPadding: '25%',
    touchMove: true,
    className: 'carousel',
    nextArrow: <CustomNextArrow />,
    prevArrow: showPrevArrow ? <CustomPrevArrow /> : undefined,
    afterChange: () => setShowPrevArrow(true),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          centerPadding: '20%',
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: '8px',
        },
      },
    ],
  }

  return (
    <Slider {...settings}>
      {contents.map(videoInfo => (
        <div
          key={videoInfo.title}
          className="mx-auto overflow-hidden"
          onClick={() => onClickVideoCard(videoInfo.videoUrl)}
        >
          <div className="relative mb-4 overflow-hidden rounded-xl">
            <img
              className="h-full w-full object-cover"
              src={videoInfo.imgUrl}
              alt={videoInfo.title}
            />
          </div>
          <div className="flex flex-col gap-1">
            <h6 className="whitespace-pre-wrap text-xl font-semibold text-[#1E1E1E]">
              {videoInfo.title}
            </h6>
            <p className="text-[15px] text-gray-500">{videoInfo.hashtag}</p>
          </div>
        </div>
      ))}
    </Slider>
  )
}

function CustomNextArrow(props: CustomArrowProps) {
  const { className, style, onClick } = props
  return (
    <div className={`${className as string}`} style={{ ...style }} onClick={onClick}>
      <div className="flex h-10 w-10 items-center justify-center rounded-full border-[0.73px] border-[#E5E5E5] bg-white lg:h-12 lg:w-12">
        <img
          className="h-6 w-6 lg:h-6 lg:w-6"
          src="https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-school-ad/mini_slide_right_arrow.png"
          alt=">"
        />
      </div>
    </div>
  )
}

function CustomPrevArrow(props: CustomArrowProps) {
  const { className, style, onClick } = props
  return (
    <div className={`${className as string}`} style={{ ...style }} onClick={onClick}>
      <div className="flex h-10 w-10 items-center justify-center rounded-full border-[0.73px] border-[#E5E5E5] bg-white lg:h-12 lg:w-12">
        <img
          className="h-6 w-6 rotate-180 lg:h-6 lg:w-6"
          src="https://d35ai18pny966l.cloudfront.net/course/kdt/kdt-school-ad/mini_slide_right_arrow.png"
          alt=">"
        />
      </div>
    </div>
  )
}

interface IInfiniteDecoReviewSliderProps {
  contents: {
    text: string
    author: string
  }[]
}

export const InfiniteDecoReviewSlider = ({ contents }: IInfiniteDecoReviewSliderProps) => {
  const settings: Settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    autoplaySpeed: 0,
    speed: 7000,
    cssEase: 'linear',
    autoplay: true,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    centerPadding: '0px',
    variableWidth: true,
  }

  return (
    <Slider {...settings}>
      {contents.map((el, idx) => (
        <div key={`부트캠프 슬라이드 ${idx}`} className="px-2 py-1 lg:px-4 lg:pb-6 lg:pt-1.5">
          <div
            key={`${el.author}`}
            className="w-full rounded-xl p-5 shadow-[0px_4px_14px_0px_rgba(0,0,0,0.05)] lg:rounded-2xl"
          >
            <div className="flex flex-col gap-3">
              <p
                className={`${styles.for_ellipsis} whitespace-pre-wrap text-sm font-medium text-gray-500 opacity-[0.52] lg:text-[15px] lg:leading-[1.6]`}
              >
                {el.text}
              </p>
              <p className="text-sm text-gray-400 lg:text-[15px]">{el.author}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  )
}

export const RtlInfiniteDecoReviewSlider = ({ contents }: IInfiniteDecoReviewSliderProps) => {
  const settings: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 7000,
    cssEase: 'linear',
    pauseOnHover: false,
    pauseOnFocus: false,
    variableWidth: true,
    arrows: false,
    rtl: true,
  }
  return (
    <Slider {...settings}>
      {contents.map((el, idx) => (
        <div key={`부트캠프 슬라이드2 ${idx}`} className="px-2 lg:px-4">
          <div
            key={`${el.author}`}
            className="w-full rounded-xl p-5 shadow-[0px_4px_14px_0px_rgba(0,0,0,0.05)] lg:rounded-2xl"
          >
            <div className="flex flex-col gap-3">
              <p
                className={`${styles.for_ellipsis} whitespace-pre-wrap text-sm font-medium text-gray-500 opacity-[0.52] lg:text-[15px] lg:leading-[1.6]`}
              >
                {el.text}
              </p>
              <p className="text-sm text-gray-400 lg:text-[15px]">{el.author}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  )
}

interface ISubReviewRenderProps {
  contents: {
    title: string
    desc: string
    who: string
  }[]
}

export const ReviewSliderItem = ({ contents }: ISubReviewRenderProps) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    touchMove: true,
    className: 'carousel',
    arrows: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          infinite: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          infinite: true,
        },
      },
    ],
  }

  return (
    <Slider {...settings}>
      {contents.map(({ title, desc, who }) => (
        <div key={title}>
          <div className="mx-2.5">
            <div className="flex flex-1 flex-col items-start justify-center gap-5 rounded-2xl bg-[#191919] px-6 py-8">
              <p className="whitespace-pre-wrap text-base font-semibold leading-[1.4] text-white lg:text-xl">
                {title}
              </p>
              <p className="whitespace-pre-wrap text-base font-medium leading-relaxed text-[#DFDFE0]">
                {desc}
              </p>
              <p className="whitespace-pre-wrap text-base font-medium text-gray-500">{who}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  )
}
